import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from 'src/app/pages/pages.service';
// import { GETCONSTANT } from '../../getConstant';
import { GETCONSTANT } from '../../constants/getConstant';
import * as moment from "moment";
import { MatTableDataSource, MatPaginator, MatSort, MatSelect, MatOption } from '@angular/material';
import { listener } from '@angular/core/src/render3';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalFilterData } from 'src/app/constants/globalFilter-data';
import { SidService } from 'src/app/component/sid/services/sid.service';


@Component({
  selector: 'app-global-filters',
  templateUrl: './global-filters.component.html',
  styleUrls: ['./global-filters.component.scss']
})
export class GlobalFiltersComponent implements OnInit {

  isCustomer: boolean = false;
  searchText: string = '';
  showListing: boolean = false;
  autoSuggestloading: boolean = false;
  previousSearchtext: string = ''
  suggestedSuppliers = [];
  suggestedInvoices = [];
  suggestedBuyer = [];
  searchResultListing = []
  showCloseIcon: boolean = false;
  customInvoiceDate: any = '';
  @Input() max: any;
  tomorrow = new Date();
  minDate = new Date();
  invoiceValidate = { startInvoiceDate: "", endInvoiceDate: "" }
  dueDateValidate = { startDueDate: "", endDueDate: "" }
  addedValidate = { startAddedDate: "", endAddedDate: "" }
  approvedValidate = { startApprovedDate: "", endApprovedDate: "" }
  expiringValidate = { startExpiringDate: "", endExpiringDate: "" }
  requestValidate = { startRequestDate: "", endRequestDate: "" }
  disbursedValidate = {startDisbursedDate: "", endDisbursedDate: ""}


  invoiceDateForm: FormGroup;
  dueDateForm: FormGroup;
  addedCredlixForm: FormGroup;
  approvedForm: FormGroup;
  expiringForm: FormGroup;
  requestForm: FormGroup;
  disbursedForm: FormGroup;
  //global filter dto
  globalFilterData: GlobalFilterData = new GlobalFilterData();
  isChannelFinancing: boolean = false;
  anchorType: string;



  constructor(public GetConstant: GETCONSTANT, public authService: AuthService,
    public pagesService: PagesService, public router: Router, private formBuilder: FormBuilder,
    private sidService: SidService) {
    this.tomorrow.setDate(this.tomorrow.getDate());
    this.invoiceDateForm = this.formBuilder.group({
      startInvoiceDate: [this.invoiceValidate.startInvoiceDate],
      endInvoiceDate: [this.invoiceValidate.endInvoiceDate]
    });
    this.dueDateForm = this.formBuilder.group({
      startDueDate: [this.dueDateValidate.startDueDate],
      endDueDate: [this.dueDateValidate.endDueDate]
    });
    this.addedCredlixForm = this.formBuilder.group({
      startAddedDate: [this.addedValidate.startAddedDate],
      endAddedDate: [this.addedValidate.endAddedDate]
    });
    this.approvedForm = this.formBuilder.group({
      startApprovedDate: [this.approvedValidate.startApprovedDate],
      endApprovedDate: [this.approvedValidate.endApprovedDate]
    });
    this.expiringForm = this.formBuilder.group({
      startExpiringDate: [this.expiringValidate.startExpiringDate],
      endExpiringDate: [this.expiringValidate.endExpiringDate]
    });
    this.requestForm = this.formBuilder.group({
      startRequestDate: [this.requestValidate.startRequestDate],
      endRequestDate: [this.requestValidate.endRequestDate]
    });
    this.disbursedForm = this.formBuilder.group({
      startDisbursedDate: [this.disbursedValidate.startDisbursedDate],
      endDisbursedDate: [this.disbursedValidate.endDisbursedDate]
    });
  }
  bizTypeFilter = this.GetConstant.BizTypeFilterList;
  InvoiceFilterList = this.GetConstant.InvoiceFilterList;
  invoiceDateList = this.GetConstant.invoiceDateList;
  dueDateList = this.GetConstant.dueDateList;
  approvedList = this.GetConstant.approvedList;
  expiringIn = this.GetConstant.expiringIn;
  addedCredlixList = this.GetConstant.addedCredlixList;
  requestedList = this.GetConstant.requestList;
  approvedCheckList = this.GetConstant.approvedCheckList;
  todayDate: any;
  tomorrowDate: any;
  yesterdayDate: any;
  setInvoiceStatus: any = ''
  @ViewChild('matRefBiz') matRefBiz: MatSelect;
  @ViewChild('matRefInvoice') matRefInvoice: MatSelect;
  @ViewChild('matRefDue') matRefDue: MatSelect;
  @ViewChild('matRefStatus') matRefStatus: MatSelect;
  @ViewChild('matRefExpiring') matRefExpiring: MatSelect;
  @ViewChild('matRefApproved') matRefApproved: MatSelect;
  @ViewChild('matRefAdded') matRefAdded: MatSelect;



  ngOnInit() {
    this.anchorType = this.authService.getAnchorDisplayName();
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if(getLocalStorage && getLocalStorage['channleFinancing']){
      this.isChannelFinancing = true;
      this.InvoiceFilterList = [
        { id: '1', roleName: 'OPEN', roleStatus: 'OPEN', isChecked: false },
        { id: '2', roleName: 'REQUESTED', roleStatus: 'REQUESTED', isChecked: false },
        { id: '3', roleName: 'DISBURSED', roleStatus: 'DISBURSED', isChecked: false },
        { id: '4', roleName: 'EXPIRED', roleStatus: 'EXPIRED', isChecked: false },
        { id: '5', roleName: 'PAID', roleStatus: 'PAID', isChecked: false }
    ]
  }
    this.pagesService.$isChannelFinancing.subscribe((res:any)=>{
        this.isChannelFinancing = res;
        setTimeout(() => {
          this.anchorType = this.authService.getAnchorDisplayName();
        }, 0);
        if(this.isChannelFinancing){
          this.InvoiceFilterList = [
            { id: '1', roleName: 'OPEN', roleStatus: 'OPEN', isChecked: false },
            { id: '2', roleName: 'REQUESTED', roleStatus: 'REQUESTED', isChecked: false },
            { id: '3', roleName: 'DISBURSED', roleStatus: 'DISBURSED', isChecked: false },
            { id: '4', roleName: 'EXPIRED', roleStatus: 'EXPIRED', isChecked: false },
            { id: '5', roleName: 'PAID', roleStatus: 'PAID', isChecked: false }
        ]
        }
    })
    if (this.authService.getIsCusotmer() || this.authService.isCredlixUser()) {
      this.isCustomer = true;
    }
    let today: any = moment()
    let tomorrow: any = moment(this.todayDate).add(1, 'days');
    let yesterday: any = moment(this.todayDate).subtract(1, 'days');
    this.todayDate = moment(today._d).format('YYYY-MM-DD');
    this.tomorrowDate = moment(tomorrow._d).format('YYYY-MM-DD');
    this.yesterdayDate = moment(yesterday._d).format('YYYY-MM-DD');
    this.pagesService._clickSubjectBehv.subscribe(res => {
      if (res == 'clicked') {
        this.issearchApplied = false;
        this.resetGlobalSearch();
        this.searchText = '';
        this.showCloseIcon = false;
      }
    });
    let sessionSearchedText = sessionStorage.getItem("searchedText");
    if (sessionSearchedText != null) {
      this.searchText = sessionSearchedText;
      this.showCloseIcon = true;
    }

    //setting global filter parameters
    this.pagesService.$globalFilterBeh.subscribe(res=>{
      if(Object.keys(res).length !== 0)
        this.globalFilterData = Object(res);
        if(this.globalFilterData.setInvoiceStatus){
          this.selectedInvoiceStatus.push(this.globalFilterData.setInvoiceStatus);
        }
    })

  }
  searchInvoiceID: any;
  searchSupplierID: any;
  // search Filter started
  SelectedBizTypeList = [];
  selectedInvoiceDate: any;
  selectedFilterType: string = 'invoice';
  selectedDueDate: any;
  selectedInvoiceStatus = [];
  searchQueryObj = {}
  searchListArray = [];
  searchedTextData = [];
  customDueDate: any = '';
  customeApproved: any = '';
  customAddedCredlix: any = '';
  customExpiringIn: any = '';
  customRequestedIn: any = '';
  customDisbursedIn: any = '';
  sendDueStartDate;
  sendDueEndDate;
  sendRequestedStartDate;
  sendRequestedEndDate;
  sendDisbursedStartDate;
  sendDisbursedEndDate;
  setBizTypeValue: any = '';
  approvedCheckedArray = [];
  setApprovedCheck = ''

  onFilterTypeChange(value) {
    this.selectedFilterType = value;
  }

  onBizTypeChange(value) {
    this.SelectedBizTypeList = []
    if (value != '') {
      this.SelectedBizTypeList.push(value)
    }
  }

  onApprovedChecked(value) {
    this.approvedCheckedArray = [];
    if (value != '') {
      this.approvedCheckedArray.push(value);
    }
  }
  sendInvoiceStartDate;
  sendInvoiceEndDate;
  onInvoiceStatusChange(value) {
    this.selectedInvoiceStatus = []
    this.globalFilterData.customDueDate = ''
    this.globalFilterData.customRequestedIn = ''
    this.globalFilterData.customDisbursedIn = ''
    this.globalFilterData.customAddedCredlix = ''
    this.globalFilterData.customExpiringIn = ''
    this.globalFilterData.customeApproved = ''
    this.sendExpiringStartDate = null
    this.sendExpiringEndDate = null
    this.sendApprovedStartDate = null
    this.sendApprovedEndDate = null
    this.sendCreatedAtStartDate = null
    this.sendCreateAtEndDate = null
    this.sendInvoiceStartDate = null
    this.sendInvoiceEndDate = null;
    this.sendDueStartDate = null;
    this.sendDueEndDate = null;
    this.sendRequestedStartDate = null;
    this.sendRequestedEndDate = null;
    if (value != '') {

      this.selectedInvoiceStatus.push(value);
    }
  }
  sendExpiringStartDate;
  sendExpiringEndDate;
  sendApprovedStartDate;
  sendApprovedEndDate;
  sendCreatedAtStartDate;
  sendCreateAtEndDate;

  onFilterChange(event, value) {
    if (value == 'invoice') {
      this.sendInvoiceStartDate = null;
      this.sendInvoiceEndDate = null
      if (event == 'today') {
        this.sendInvoiceStartDate = this.todayDate;
        this.sendInvoiceEndDate = this.todayDate;
      }
      else if (event == 'tommorow') {
        this.sendInvoiceStartDate = this.tomorrowDate;
        this.sendInvoiceEndDate = this.tomorrowDate;
      }
      else if (event == 'yesterday') {
        this.sendInvoiceStartDate = this.yesterdayDate;
        this.sendInvoiceEndDate = this.yesterdayDate;
      }
      else {
        this.globalFilterData.customInvoiceDate = event;
        this.invoiceDateForm.reset();
      }
    }

    else if (value == 'due') {
      this.sendDueStartDate = null;
      this.sendDueEndDate = null;
      if (event == 'today') {
        this.sendDueStartDate = this.todayDate;
        this.sendDueEndDate = this.todayDate;
      }
      else if (event == 'tommorow') {
        this.sendDueStartDate = this.tomorrowDate;
        this.sendDueEndDate = this.tomorrowDate;
      }
      else if (event == 'yesterday') {
        this.sendDueStartDate = this.yesterdayDate;
        this.sendDueEndDate = this.yesterdayDate;
      }
      else {
        this.globalFilterData.customDueDate = event;
        this.dueDateForm.reset();
      }
    }
    else if (value == 'expiring') {
      this.sendExpiringStartDate = null;
      this.sendExpiringEndDate = null;
      if (event == 'today') {
        this.sendExpiringStartDate = this.todayDate;
        this.sendExpiringEndDate = this.todayDate;
      }
      else if (event == 'tommorow') {
        this.sendExpiringStartDate = this.tomorrowDate;
        this.sendExpiringEndDate = this.tomorrowDate;
      }
      else if (event == 'yesterday') {
        this.sendExpiringStartDate = this.yesterdayDate;
        this.sendExpiringEndDate = this.yesterdayDate;
      }
      else {
        this.globalFilterData.customExpiringIn = event;
        this.expiringForm.reset();
      }
    }

    else if (value == 'approved') {
      this.sendApprovedStartDate = null;
      this.sendApprovedEndDate = null;
      if (event == 'today') {
        this.sendApprovedStartDate = this.todayDate;
        this.sendApprovedEndDate = this.todayDate;
      }
      else if (event == 'tommorow') {
        this.sendApprovedStartDate = this.tomorrowDate;
        this.sendApprovedEndDate = this.tomorrowDate;
      }
      else if (event == 'yesterday') {
        this.sendApprovedStartDate = this.yesterdayDate;
        this.sendApprovedEndDate = this.yesterdayDate;
      }
      else {
        this.globalFilterData.customeApproved = event;
        this.approvedForm.reset();
      }
    }

    else if (value == 'added') {
      this.sendCreatedAtStartDate = null;
      this.sendCreateAtEndDate = null;
      if (event == 'today') {
        this.sendCreatedAtStartDate = this.todayDate;
        this.sendCreateAtEndDate = this.todayDate;
      }
      else if (event == 'tommorow') {
        this.sendCreatedAtStartDate = this.tomorrowDate;
        this.sendCreateAtEndDate = this.tomorrowDate;
      }
      else if (event == 'yesterday') {
        this.sendCreatedAtStartDate = this.yesterdayDate;
        this.sendCreateAtEndDate = this.yesterdayDate;
      }
      else {
        this.globalFilterData.customAddedCredlix = event;
        this.addedCredlixForm.reset()
      }
    }

    else if (value == 'request') {
      this.sendRequestedStartDate = null;
      this.sendRequestedEndDate = null;
      if (event == 'today') {
        this.sendRequestedStartDate = this.todayDate;
        this.sendRequestedEndDate = this.todayDate;
      }
      else if (event == 'yesterday') {
        this.sendRequestedStartDate = this.yesterdayDate;
        this.sendRequestedEndDate = this.yesterdayDate;
      }
      else {
        this.globalFilterData.customRequestedIn = event;
        this.requestForm.reset();
      }
    }

    else if (value == 'disbursed') {
      this.sendDisbursedStartDate = null;
      this.sendDisbursedEndDate = null;
      if (event == 'today') {
        this.sendDisbursedStartDate = this.todayDate;
        this.sendDisbursedEndDate = this.todayDate;
      }
      else if (event == 'yesterday') {
        this.sendDisbursedStartDate = this.yesterdayDate;
        this.sendDisbursedEndDate = this.yesterdayDate;
      }
      else {
        this.globalFilterData.customDisbursedIn = event;
        this.disbursedForm.reset();
      }
    }
  }


  changeFromDatePicker(event, value) {
    if (value == 'invoice') {
      this.sendInvoiceStartDate = null;
      let startDate = event.value;
      this.sendInvoiceStartDate = moment(startDate).format('YYYY-MM-DD');
    }
    if (value == 'due') {
      this.sendDueStartDate = null;
      let start = event.value;
      this.sendDueStartDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == 'expiring') {
      this.sendExpiringStartDate = null;
      let start = event.value;
      this.sendExpiringStartDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == 'approved') {
      this.sendApprovedStartDate = null;
      let start = event.value;
      this.sendApprovedStartDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == "added") {
      this.sendCreatedAtStartDate = null;
      let start = event.value;
      this.sendCreatedAtStartDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == "request") {
      this.sendRequestedStartDate = null;
      let start = event.value;
      this.sendRequestedStartDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == "disbursed") {
      this.sendDisbursedStartDate = null;
      let start = event.value;
      this.sendDisbursedStartDate = moment(start).format('YYYY-MM-DD');
    }

  }


  changeEndDatePicker(event, value) {
    if (value == 'invoice') {
      this.sendInvoiceEndDate = null;
      let startDate = event.value;
      this.sendInvoiceEndDate = moment(startDate).format('YYYY-MM-DD');
    }
    if (value == 'due') {
      this.sendDueEndDate = null;
      let start = event.value;
      this.sendDueEndDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == 'expiring') {
      this.sendExpiringEndDate = null;
      let start = event.value;
      this.sendExpiringEndDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == 'approved') {
      this.sendApprovedEndDate = null;
      let start = event.value;
      this.sendApprovedEndDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == "added") {
      this.sendCreateAtEndDate = null;
      let start = event.value;
      this.sendCreateAtEndDate = moment(start).format('YYYY-MM-DD');
    }
    if (value == "request") {
      this.sendRequestedEndDate = null;
      let start = event.value;
      this.sendRequestedEndDate = moment(start).format('YYYY-MM-DD');
    }
    if(value == "disbursed") {
      this.sendDisbursedEndDate = null;
      let start = event.value;
      this.sendDisbursedEndDate = moment(start).format('YYYY-MM-DD');
    }

  }

  onSearchChange(value) {
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.showListing = true;
    if (value.trim().length > 1 && (!this.autoSuggestloading || (value.trim().length - this.previousSearchtext.length) >= 2)) {
      this.previousSearchtext = value.trim();
      this.autoSuggestloading = true;
      this.showCloseIcon = true;
      let obj = {
        "size": 10,
        "from": 0,
        "searchTerm": this.searchText
      }
      if (this.searchedTextData != null && this.searchedTextData.length != 0) {
        obj['supplier'] = this.searchedTextData[0].gstNo
      }
      this.suggestedInvoices = []
      this.suggestedSuppliers = []
      setTimeout(function () {
        this.autoSuggestloading = false;
      }.bind(this), 500);
      if(this.authService.isSIDRoleType()){
        if(localStorage.getItem('credlixUserID')){
          obj['masterAccountId'] = localStorage.getItem('credlixUserID');
        }
        obj['indexNames'] = [ "credlix_sid_buyers","credlix_sid_suppliers","credlix_sid_invoices"];
        this.sidService.buyerAutoSuggestList(obj).subscribe(data => {
          this.pagesService.offSpinner();
          this.searchResultListing = data['result']['result'];
          let suppliers = this.searchResultListing.filter(value => {
            return value.documentType.toLowerCase() == 'supplier'
          })
          let invoices = this.searchResultListing.filter(value => {
            return value.documentType.toLowerCase() == 'invoice'
          })
          let buyers = this.searchResultListing.filter(value => {
            return value.documentType.toLowerCase() == 'buyer'
          })
          this.suggestedSuppliers = suppliers
          this.suggestedInvoices = invoices
          this.suggestedBuyer = buyers
          sessionStorage.setItem("searchedText",this.searchText)
          sessionStorage.setItem("documentType", this.searchResultListing['documentType']);

        })
      }else if(getLocalStorage && getLocalStorage['channleFinancing']){
        if(localStorage.getItem('credlixUserID')){
          obj['masterAccountId'] = localStorage.getItem('credlixUserID');
        }
        this.pagesService.autoCompleteSearchVedanta(obj).subscribe(data => {
          this.pagesService.offSpinner();
          this.searchResultListing = data['result'];
          let invoices = this.searchResultListing.filter(value => {
            return value.documentType.toLowerCase() == 'invoice'
          })
          let buyers = this.searchResultListing.filter(value => {
            return value.documentType.toLowerCase() == 'buyer'
          })
          this.suggestedInvoices = invoices
          this.suggestedBuyer = buyers
          sessionStorage.setItem("searchedText",this.searchText)
        })
      }
      else{
        if(this.authService.isCredlixUser()){
          obj['masterAccountId'] = localStorage.getItem('credlixUserID');
        }
        this.pagesService.autoCompleteSearch(obj).subscribe(data => {
          this.pagesService.offSpinner();
          this.searchResultListing = data['result'];
          let suppliers = this.searchResultListing.filter(value => {
            return value.documentType.toLowerCase() == 'supplier'
          })
          let invoices = this.searchResultListing.filter(value => {
            return value.documentType.toLowerCase() == 'invoice'
          })
          this.suggestedSuppliers = suppliers
          this.suggestedInvoices = invoices
          sessionStorage.setItem("searchedText",this.searchText)
        })
      }
    }
  }
  resetSearchText() {
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.searchText = "";
    this.searchText = '';
    this.searchedTextData = []
    this.showCloseIcon = false;
    this.resetGlobalSearch();
    this.issearchApplied = false;
    sessionStorage.removeItem('requestObject')
    sessionStorage.removeItem("searchedText");
    sessionStorage.removeItem("searchTextedId");
    if(getLocalStorage && getLocalStorage['channleFinancing']){
      this.router.navigate(['/channelFinance']);
    }
    else if(!this.authService.isSIDRoleType()){
      this.router.navigate(['/supplierLinks']);
    }else{
      this.router.navigate(['/sid/listing/supplier']);
    }
  }
  searchValueadded = [];
  applySearch(list) {
    console.log(list)
    this.searchedTextData = []
    if (list != null && (list.toString().trim() == "" || list.toString().trim().length < 2)) {
      return
    }
    this.showListing = false;
    if (list.hasOwnProperty('searchkey')) {
      this.searchText = list.searchkey
      sessionStorage.setItem("searchedText", this.searchText);
      sessionStorage.setItem("documentType", list.documentType);

    }
    if (list != '' && (!list.hasOwnProperty('searchkey'))) {
      if(this.isChannelFinancing){
        sessionStorage.setItem("searchedText", list);
        sessionStorage.setItem("documentType", 'Invoice');

      }
      list = {
        "documentType": 'invoice',
        "searchkey": this.searchText
      }
    }
    this.searchedTextData.push(list);
    if(this.isChannelFinancing){
      this.router.navigate(['/financeListing']);
    }else{
      this.applyGlobalSearchFilter(false);
    }
  }

  storeSupplierID;
  storedSupplierText;
  applySupplierSearch(list, pageType?) {
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.showListing = false;
    sessionStorage.setItem("isNavigation","true");
    if (list != '' && (!list.hasOwnProperty('searchkey'))) {
      this.storedSupplierText = list;
      if(getLocalStorage && getLocalStorage['channleFinancing'] && !this.authService.isSIDRoleType()){
        if(pageType == 'buyer'){
          this.router.navigate(['/channelFinance', {
            id: 'supplierText', param1: this.storedSupplierText
          }]);
        }
      }
      else if(this.authService.isSIDRoleType()){
        if(pageType == 'buyer'){
          this.router.navigate(['sid/listing/buyer/', {
            id: 'supplierText', param1: this.storedSupplierText
          }]);
        }
        else{
          this.router.navigate(['sid/listing/supplier/', {
            id: 'supplierText', param1: this.storedSupplierText
          }]);
        }
      }else{
        this.router.navigate(['supplierLinks/', {
          id: 'supplierText', param1: this.storedSupplierText
        }]);
      }
    }
    else {
      this.storeSupplierID = list.id;
      this.searchText = list.searchkey
      sessionStorage.setItem("searchedText", this.searchText);
      sessionStorage.setItem("documentType", list.documentType);
      sessionStorage.setItem('searchTextedId', this.storeSupplierID);
      if(getLocalStorage && getLocalStorage['channleFinancing'] && !this.authService.isSIDRoleType()){
        if(pageType == 'buyer'){
          this.router.navigate(['/channelFinance']);
        }
      }
      else if(this.authService.isSIDRoleType()){
        if(list.documentType == 'buyer'){
          this.router.navigate(['sid/listing/buyer/' + this.storeSupplierID]);
        }
        else if(list.documentType == 'supplier'){
          this.router.navigate(['sid/listing/supplier/' + this.storeSupplierID]);
        }
        else{
          // this.router.navigate([`sid/invoiceListing/supplier/${list.} ${this.storeSupplierID}`]);
        }
      }else{
        this.router.navigate(['supplierLinks/' + this.storeSupplierID]);
      }
    }

  }


  invoicesvalues = [];
  issearchApplied: boolean = false;
  applyGlobalSearchFilter(checkbtnClick) {
    this.showListing = false;
    if (checkbtnClick == true) {
      this.issearchApplied = true;
      this.searchText = 'Search Filter Applied'
      this.showCloseIcon = true;
      this.searchedTextData = [];
      sessionStorage.setItem("searchedText",this.searchText);
      // sessionStorage.setItem("documentType", list.documentType);

    }
    else if (checkbtnClick == false) {
      this.resetGlobalSearch();
    }
    this.searchQueryObj = {
      "where": {
      }
    }
    if (this.SelectedBizTypeList != null && this.SelectedBizTypeList.length != 0) {
      if (this.SelectedBizTypeList.length < 2) {
        if (this.SelectedBizTypeList[0] == 'isAbfrl') {
          this.searchQueryObj['where']['isAbfrl'] = {
            "type": "search",
            "field": "isAbfrl",
            "value": true
          }
        }
        else if (this.SelectedBizTypeList[0] == 'moglix') {
          this.searchQueryObj['where']['isAbfrl'] = {
            "type": "search",
            "field": "isAbfrl",
            "value": false
          }
        }
      }
    }
    if (this.globalFilterData.customeApproved != undefined && this.globalFilterData.customeApproved != -1 && this.globalFilterData.customeApproved != 'today'
      && this.globalFilterData.customeApproved != 'yesterday' && this.globalFilterData.customeApproved != 'tommorow' && this.globalFilterData.customeApproved == 'approved'
      && this.globalFilterData.customeApproved != -1 && this.globalFilterData.customeApproved != '') {
      this.searchQueryObj['where']['isEligibleForEP'] = {
        "type": "search",
        "field": "isEligibleForEP",
        "value": false
      }
    }

    if (this.selectedInvoiceStatus != null && this.selectedInvoiceStatus != undefined && this.selectedInvoiceStatus.length > 0
      && !this.isChannelFinancing) {
        if(this.selectedInvoiceStatus.includes('DISBURSED')) {
          this.selectedInvoiceStatus.pop();
          this.selectedInvoiceStatus.push('PAID', 'PARTIALLY_PAID');
        }
      this.searchQueryObj['where']['activeEarlyPayment.status'] = {
        "type": "in",
        "value": this.selectedInvoiceStatus
      }
    }

    if (this.selectedInvoiceStatus != null && this.selectedInvoiceStatus != undefined && this.selectedInvoiceStatus.length > 0
      && this.isChannelFinancing) {
      this.searchQueryObj['where']['status'] = {
        "type": "loaninvoice_in",
        "value": this.selectedInvoiceStatus
      }
    }

    if (this.globalFilterData.searchInvoiceID != '' && this.globalFilterData.searchInvoiceID != null
    && !this.isChannelFinancing) {
      this.searchQueryObj['where']['invoiceNumber'] = {
        "type": "search",
        "field": "invoiceNumber",
        "value": this.globalFilterData.searchInvoiceID
      }
    }

    if (this.globalFilterData.searchInvoiceID != '' && this.globalFilterData.searchInvoiceID != null
    && this.isChannelFinancing) {
      this.searchQueryObj['where']['searchKey'] = {
        "type": "search",
        "field": "searchKey",
        "value": this.globalFilterData.searchInvoiceID
      }
    }

    if (this.globalFilterData.searchSupplierID != '' && this.globalFilterData.searchSupplierID != null
     && !this.isChannelFinancing) {
      this.searchQueryObj['where']['supplierId'] = {
        "type": "search",
        "field": "supplierId",
        "value": this.globalFilterData.searchSupplierID
      }
    }

    if (this.globalFilterData.searchBuyerID != '' && this.globalFilterData.searchBuyerID != null
    && this.isChannelFinancing) {
     this.searchQueryObj['where']['buyerId'] = {
       "type": "search",
       "field": "buyerId",
       "value": this.globalFilterData.searchBuyerID
     }
   }


    if (this.globalFilterData.customInvoiceDate != undefined && this.globalFilterData.customInvoiceDate != null && this.globalFilterData.customInvoiceDate != 'today'
      && this.globalFilterData.customInvoiceDate != 'yesterday' && this.globalFilterData.customInvoiceDate != 'tommorow' && this.globalFilterData.customInvoiceDate != -1 && this.globalFilterData.customInvoiceDate != '') {
      this.searchQueryObj['where']['invoiceDate'] = {
        "type": "range",
        "custom": this.globalFilterData.customInvoiceDate,
        "range_type": 'last'

      }
    }

    if (this.sendInvoiceStartDate != null && this.sendInvoiceEndDate != null) {
      this.searchQueryObj['where']['invoiceDate'] = {
        "type": "range",
        "start_value": this.sendInvoiceStartDate,
        "end_value": this.sendInvoiceEndDate
      }
    }

    if (this.globalFilterData.customDueDate != undefined && this.globalFilterData.customDueDate != null && this.globalFilterData.customDueDate != 'today'
      && this.globalFilterData.customDueDate != 'yesterday' && this.globalFilterData.customDueDate != 'tommorow' && this.globalFilterData.customDueDate != -1 && this.globalFilterData.customDueDate != '') {
      this.searchQueryObj['where']['dueDate'] = {
        "type": "range",
        "custom": this.globalFilterData.customDueDate,
        "range_type": 'next'
      }
    }

    if (this.sendDueEndDate != null && this.sendDueEndDate != null) {
      this.searchQueryObj['where']['dueDate'] = {
        "type": "range",
        "start_value": this.sendDueStartDate,
        "end_value": this.sendDueEndDate
      }
    }

    if (this.globalFilterData.customExpiringIn != undefined && this.globalFilterData.customExpiringIn != -1 && this.globalFilterData.customExpiringIn != 'today'
      && this.globalFilterData.customExpiringIn != 'yesterday' && this.globalFilterData.customExpiringIn != 'tommorow' && this.globalFilterData.customExpiringIn != -1 && this.globalFilterData.customExpiringIn != '') {
      this.searchQueryObj['where']['expiryOn'] = {
        "type": "range",
        "custom": this.globalFilterData.customExpiringIn,
        "range_type": 'next'
      }
    }
    if (this.sendExpiringStartDate != null && this.sendExpiringEndDate != null) {
      this.searchQueryObj['where']['expiryOn'] = {
        "type": "range",
        "start_value": this.sendExpiringStartDate,
        "end_value": this.sendExpiringEndDate
      }
    }

    if (this.globalFilterData.customeApproved != undefined && this.globalFilterData.customeApproved != -1 && this.globalFilterData.customeApproved != 'today'
      && this.globalFilterData.customeApproved != 'yesterday' && this.globalFilterData.customeApproved != 'tommorow' && this.globalFilterData.customeApproved != 'approved'
      && this.globalFilterData.customeApproved != -1 && this.globalFilterData.customeApproved != '') {
      this.searchQueryObj['where']['epEligibleDate'] = {
        "type": "range",
        "custom": this.globalFilterData.customeApproved,
        "range_type": 'last'
      }
    }
    if (this.sendApprovedStartDate != null && this.sendApprovedEndDate != null) {
      this.searchQueryObj['where']['epEligibleDate'] = {
        "type": "range",
        "start_value": this.sendApprovedStartDate,
        "end_value": this.sendApprovedEndDate
      }
    }

    if (this.globalFilterData.customAddedCredlix != undefined && this.globalFilterData.customAddedCredlix != -1 && this.globalFilterData.customAddedCredlix != 'today'
      && this.globalFilterData.customAddedCredlix != 'yesterday' && this.globalFilterData.customAddedCredlix != 'tommorow'
      && this.globalFilterData.customAddedCredlix != -1 && this.globalFilterData.customAddedCredlix != '') {
      this.searchQueryObj['where']['createdAt'] = {
        "type": "range",
        "custom": this.globalFilterData.customAddedCredlix,
        "range_type": 'last'
      }
    }
    if (this.sendCreatedAtStartDate != null && this.sendCreateAtEndDate != null) {
      this.searchQueryObj['where']['createdAt'] = {
        "type": "range",
        "start_value": this.sendCreatedAtStartDate,
        "end_value": this.sendCreateAtEndDate
      }
    }


    if (this.globalFilterData.customRequestedIn != undefined && this.globalFilterData.customRequestedIn != -1 && this.globalFilterData.customRequestedIn != 'today'
      && this.globalFilterData.customRequestedIn != 'yesterday' && this.globalFilterData.customRequestedIn != 'tommorow'
      && this.globalFilterData.customRequestedIn != -1 && this.globalFilterData.customRequestedIn != '') {
      this.searchQueryObj['where']['activeEarlyPayment.requestDate'] = {
        "type": "range",
        "custom": this.globalFilterData.customRequestedIn,
        "range_type": 'last'
      }
    }
    if (this.sendRequestedStartDate != null && this.sendRequestedEndDate != null) {
      this.searchQueryObj['where']['activeEarlyPayment.requestDate'] = {
        "type": "range",
        "start_value": this.sendRequestedStartDate,
        "end_value": this.sendRequestedEndDate
      }
    }

    if (this.globalFilterData.customDisbursedIn != undefined && this.globalFilterData.customDisbursedIn != -1 && this.globalFilterData.customDisbursedIn != 'today'
      && this.globalFilterData.customDisbursedIn != 'yesterday' && this.globalFilterData.customDisbursedIn != 'tommorow'
      && this.globalFilterData.customDisbursedIn != -1 && this.globalFilterData.customDisbursedIn != '') {
      this.searchQueryObj['where']['activeEarlyPayment.expectedPaymentDate'] = {
        "type": "range",
        "custom": this.globalFilterData.customDisbursedIn,
        "range_type": 'last'
      }
    }
    if (this.sendDisbursedStartDate != null && this.sendDisbursedEndDate != null) {
      this.searchQueryObj['where']['activeEarlyPayment.expectedPaymentDate'] = {
        "type": "range",
        "start_value": this.sendDisbursedStartDate,
        "end_value": this.sendDisbursedEndDate
      }
    }

    if (this.searchedTextData != null && this.searchedTextData.length != 0) {
      let values = []
      for (let i = 0; i < this.searchedTextData.length; i++) {
        let selectedData = this.searchedTextData[i];
        let field: string;
        let value: string;
        if (selectedData.documentType == 'supplier') {
          field = 'supplierGSTN',
            value = selectedData.uniqueKey
        }
        else {
          field = 'invoiceNumber',
            value = selectedData.searchkey
        }
        values.push({
          "field": field,
          "value": value
        })
      }

      this.searchQueryObj['where']['invoiceNumber'] = {
        "type": "search",
        "operator": "AND",
        "value": values
      }
    }
    else {
      if (!this.issearchApplied) {
        this.searchQueryObj['where']['invoiceNumber'] = {
          "type": "search",
          "value": this.searchText
        }
      }
    }
    this.searchQueryObj["accessAs"] = this.isCustomer ? 'customerGSTN' : 'supplierGSTN';
    this.router.navigateByUrl('/').then(() => {
      if(!this.isChannelFinancing && !this.authService.isSIDRoleType()){
        this.router.navigate(['/earlyPayments/' + "search/"]);
      }
      else if(this.authService.isSIDRoleType()){
        if(this.searchResultListing && this.searchResultListing[0].documentType == "Invoice"){
          this.sidService.getInvoiceDetails(this.searchResultListing[0].id).subscribe(res=>{
            let urlData= this.searchResultListing[0];
            this.router.navigateByUrl(`/sid/editInvoice/${res['result']['supplierAccount']['searchKey']}/${urlData.id}/${res['result'].status}/${res['result']['supplierAccount']['id']}/${urlData['supplierName'].replace(/[()]/g, '')}`);
          })
        }
      }
      else{
        this.router.navigate(['/financeListing']);
      }
      sessionStorage.setItem("GlobalSearch", JSON.stringify(this.searchQueryObj));
      sessionStorage.setItem("GlobalSearchData", JSON.stringify(this.globalFilterData));
    });
  }


  resetGlobalSearch() {
    this.selectedInvoiceStatus = [];
    this.approvedCheckedArray = [];
    this.SelectedBizTypeList = [];
    this.globalFilterData = new GlobalFilterData();
    this.setApprovedCheck = ''
    this.sendExpiringStartDate = null
    this.sendExpiringEndDate = null
    this.sendApprovedStartDate = null
    this.sendApprovedEndDate = null
    this.sendCreatedAtStartDate = null
    this.sendCreateAtEndDate = null
    this.sendInvoiceStartDate = null
    this.sendInvoiceEndDate = null;
    this.sendDueStartDate = null;
    this.sendDueEndDate = null;
    this.sendRequestedStartDate = null;
    this.sendRequestedEndDate = null;
  }
}

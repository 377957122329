export const urls = {

  //QA UI
  userSignup: "users/signup",
  userSignIN: "users/signin",
  userVerification: "users/otp/generate",
  verifyOTP: "users/otp/verify",
  accountSetUp: "users/account",
  checkEmailExist: "users/check-user",
  getUserByToken: "users/token",
  getAllCustomer: "users/getAll",
  getPaymentTerms: "users/payment-terms",
  industryList: "industry",
  states: "country/states",
  country: "country",
  invoiceCreation: "invoice",
  updateAddress: "address",
  accountInfo: "users/account",
  getPurchaseOrders: "entities/purchase-orders/search",
  getSuppliersInvoice: "entities/supplier-invoices/search",
  getCustomerInvoice: "entities/customer-invoices/search",
  getEarlyPaymentInvoices: "entities/early-payment-invoices/search",
  searchDiscountDebitNotes: "anchor-discount-dn/search",
  downloadDiscountDebitNotes: "anchor-discount-dn/download",
  createEarlyPaymentRequest: "entities/early-payment/create",
  downloadPaymentSchedule: "early-payment/export",
  getDocumentListByURl: "invoice/supplier/",
  markEligibility: "invoice/mark-eligibility",
  getDoumentPoListByURL: "purcahseOrders/",
  getSupplierLinks: "entities/suppliers/search",
  addSuppliers: "data-import/sync-moglix-supplier",
  changePassword: "users/change-password",
  forgotPassword: "users/forgot-password",
  resetPassword: "users/verify-reset-password",
  customerMarkPaid: "entities/early-payment/update",
  editSupplierDetails: "users/upload-custom-config",
  editBulkSupplierDetails: "users/upload-bulk-custom-config",
  customAuthentication: "users/authenticate-supplier",
  usedEPAmount: "users/used-ep-amount",
  autoCompleteSearchResult: "entities/autosuggest/search",
  downloadEPInvoiceResults: "entities/early-payment-invoices/search/export",
  financerList: "entities/financiers/search",
  bookingUploader: 'invoice/invoice-booking/v2',
  CIDMappingUploader: 'invoice/bussiness-mapping',
  ledgerMapping: "invoice/invoice-mapping/bussiness/v2",
  lenderConfig: 'account-config/upload/v2',
  lenderUTRMap: 'invoice/utr/upload/v2',
  tradeDiscountReport: "invoice/invoice-debitnote-download",
  downloadCustomerInvoices: "entities/early-payment-invoices/customer/export",
  downloadSupplierInvoices: "entities/search/suppliers/export",
  loadJobs: "entities/early-payment-invoices/scheduledJobs",
  downloadfile: 'entities/early-payment-invoices/search/download',
  refreshStatus: "entities/early-payment-invoices/search/status",
  supplierRefresh: "data-import/sync-vendor-bill-by-supplier",
  reconcileSheet: "invoice/supplier-reco",
  ABRFTemplateDownload: "invoice/invoice-mapping-abfrl/template",
  uploadedBulkUser: "data-import/register-suppliers-bulk",
  getGstPdf: "generate-pdf",
  getReports: "get-gst-reports",
  downloadGst: "get-gst-report/gst",

  //userManagement
  getAllRoles: "user-role/roles",
  getAllPermissions: "user-role/get-permissions-list",
  getAllPlants: "users/account",
  adduser: "users/create-user-with-role",
  createUserRole: "user-role/create-custom-role",
  userDetails: "users/get-users",
  userAssignRoles: "users/assign-role",
  getAllStates: "country/states",
  deleteUserRole: "user-role/user-roles",
  userAutosuggest: "users/search",
  refreshToken: 'user-role/get-token',
  bulkUserAdded: 'user-role/bulk-upload',
  userStatusUpdate: "users/update-status",
  getUserProfile: 'sub-user',
  checkRoleID: 'user-role/check-role-delete',
  importConfig: 'import-config',
  getRequestPayment: 'invoice/invoice-ep-details',
  companyDetails: 'company-info',
  directorDetails: 'company-info/directors',
  uploadDocuments: 'documentinfo/upload',
  verifyDetails: 'company-info/details',
  downloadDocuments: 'documentinfo/download',
  checkOnBoardGSTIN: "onboard-data",
  syncGST: "early-payment/data-sync",
  syncPayment: "invoice/invoice-payment-with-ep-date",
  submitDetails: 'company-info/submit',
  getRequestMultiplePayment: 'invoice/invoice-ep-details/multiple',
  getBuyerListing: "accounts?accountType=BUYER",
  getRMList: "users/v2/by-authority?authorities=MANAGE_BUSINESS_ACCOUNT",

  //Countrys Rating
  COUNTRY_RATING: 'country-rating',

  //vedanta API's
  checkVedantaUser: 'users/v2/vedanta/signin',
  vedantaUserDetails: "users/v2/vedanta/userdetails",
  signUpVedantaUser: 'users/v2/vedanta/signup',
  // getBuyerInvoiceListing : "loan-invoice",
  getBuyerInvoiceListing: "loan-request/loan-request",
  getAnchorList: "master-account/anchors",
  getAnchorListOfFinancing: "master-account/financing/anchors",
  getLenderAnchorList: 'financier/anchors',
  getRepayments: "loan-request/get-repayments",
  onBoarding: "company-info/onboarding",
  verifyInfo: "company-info/mark-verified",
  downloadZip: "documentinfo/download-zip",
  deleteDocument: "documentinfo/delete",
  getLeadsLists: "accounts/lender/search",
  updateLeadDetails: "lead-tracker/update-tracker-lead",
  addToLead: "accounts/lead",
  getCiData: "system-invoice",
  getInvoicesCount: "sid-invoice/invoice-stats",
  getEpInvoicesCount: "invoice/invoice-stats",
  getAllStats: "entities/get-all-stats",
  getAllLenderStats: 'entities/lender-stats',
  downloadSystemInvoice: 'system-invoice/report',
  downloadSystemInvoiceByMonth: 'system-invoice/generate-all',
  getCiById: 'system-invoice/by-invoiceNumber',
  ledgerReport: 'ledger/master-ledger',
  downloadLedgerReport: 'ledger/master-ledger-download',
  downloadEPReport: "virtual-accounts/download-escrow-accounts-transactions",


  whatsappMessageContent: "messenger/get-template",
  whatsappInvoiceMessageContent: "messenger/get-invoice-template",
  requestEPOTPVerify: 'messenger/verify-email-request-template',
  requestEPtemplateValues: 'messenger/email/template-values',
  whatsappSubUserList: 'sub-user/contacts',
  getUserList: "accounts/users",
  markWhatsappNumber: 'sub-user/mark-whatsapp',
  sendWhatsappMsg: 'messenger/whatsapp/send-message',
  sendEmailMsg: 'messenger/email/send-message',
  checkWhatsapplogged: 'sub-user/whatsapp',
  supplierOnboard: 'borrower/upload/V2',
  anchorInvoice: 'borrower/invoice/upload/v3',
  debitNoteUpload: 'debit-note/upload',
  EPMarkDelayed: 'early-payment/mark-delayed',
  cancelEPInvoice: 'early-payment/mark-cancelled',
  EPhistory: 'early-payment/history',
  bulkMarkDelayedEP: 'early-payment/mark-delayed/bulk/v2',
  bulkCanceledEP: 'early-payment/mark-cancelled/bulk/v2',
  GRNMapping: 'invoice/invoice-mapping/grn-details/v2',
  getSupplierBankDetails: "bank-details",
  getOTPRequest: "otp-request",
  setPrimaryBankAccount: "bank-details/set-primary",
  deleteBankAccount: "bank-details/mark-deleted",
  leadComment: 'comment',
  getLeadComment: 'comment/business-account',
  setBasicDetails: 'accounts/supplier/basic-details',
  getEPComments: 'comment/business-account',
  syncDebitNote: 'credlix-discount-debit-notes/sync',

  //Borrower
  getAllBorrowerStats: 'buyer/get-all-buyer-stats',
  getBuyerStats: 'buyer/get-buyer-stats',
  getMetrics: 'borrower/metrics',
  downloadBuyer: 'buyer/export',
  discountPopup: 'abfrl-interest',
  invoiceDirect: 'borrower/jkfenner/syncsuppliersinvoicelist',
  vendorsDirect: 'borrower/jkfenner/syncsupplierlist',
  bulkInvoiceSync:"borrower/jkfenner/syncsupplierinvoicelist",
  epStatus: "early-payment/refresh/payment",

  //Escrow Account
  getSupplierEscrowPayments: "virtual-accounts/get-escrow-accounts-transactions-of-supplier",
  uploadSupplierPaymentFile: 'paymentAdvice?utr=',
  getPayouts: 'transaction-files',
  uploadPayoutFile: 'transaction-files/upload-anchor-transaction-file',
  uploadedJobSchedule: 'scheduled-jobs',
  invoicesPayout: 'transaction-files/file-entries',
  supplierPayouts: 'transaction-files/borrower-wise-entries',
  supplierTransactions: 'virtual-accounts/get-virtual-accounts-transactions',
  reviewPayoutFile: 'transaction-files/mark-reviewed',
  fileTransaction: 'anchor-virtual-account/get-file-transactions',
  verifyTransactions: 'transaction-files/verify-file',
  getEscrowOTP: 'anchor-virtual-account/send-file-transactions-otp',
  sendPaymentOTP: 'anchor-virtual-account/verify-create-file-transactions',
  getEscrowPayments: "virtual-accounts/get-escrow-accounts-transactions",
  getEscrowDetail: 'virtual-accounts/get-escrow-entry-details',
  unmapTransaction: 'transaction-files/unmap-file-entries-escrow',
  mapTransaction: 'transaction-files/map-file-entries-escrow',
  escrowSystemStats: 'dashboard/get-escorw-system-stats',
  downloadUploadedFile: 'transaction-files/download',
  rejectUploadedFile: 'transaction-files/reject-file',
  downloadPayoutSheet: 'transaction-files/payouts/download',
  downloadPayoutTransactionSheet: 'transaction-files/transactions/download',
  ledgerReportDownload: 'reports/get-ledger-reports',
  payoutBalance: 'dashboard/get-escorw-bank-stats',
  getAnchorDashboard: 'dashboard/get-anchor-dashboard',
  balanceSheetDownload: 'transaction-files/payout-match/download',
  supplierInvoicesSheet: 'reports/get-anchor-account-balances',
  unmappedBalanceSheet: 'reports/get-anchor-unsettled-transactions',
  anchorPaymentReport: 'reports/get-anchor-payments-reports',
  lenderPaymentReport: 'reports/get-lender-payments-reports',
  manualCreditForm: 'api/payment/credlix-callback',
  payoutReport: 'reports/report-type',
  multipleAccount: 'users/v2/get-access-token',
  getRolesByAccount: 'users/v2/get-user-roles',
  bydefaultUserRole: 'user-role/update-default-role',
  DownlaodEscrowPaymentDocs: 'paymentAdvice/download?utr=',
  approveEscrowPayment: 'paymentAdvice/approve',
  rejectEscrowPayment: 'paymentAdvice/reject',
  getTransactionHistory: 'paymentAdvice/fetch-history',

  //upload-mapping
  getUploadMappingHeaders: 'file-config/get-headers-with-records',
  updateImportConfig: "import-config/update-index",
  //updateImportConfig:"import-config/create",

  // Credlix-kanban
  fetchDomesticData:'lane-task',
  bulkUpload:'documents/bulk-upload-attachment',
  saveNewLeadData:'lane-task/update-new-leads/',
  fetchByPan:'karza/gstins-by-pan',
  sendForConsent:'lane-task/send-consent',
  consentStatus:'lane-task/consent-status',
  generateOtpUrls:'lane-task/generate-consent-otp',
  verifyOtp:'lane-task/update-consent-status',
  fetchByGst:'karza/gstin-details',
  fetchByCin:'karza/mca-signatories-by-cin',
  saveDetailsAndDocument:'lane-task/update-details-documentation/',
  changeLane:'lane-task/assign-lanetask-to-other-lane',
  showAll:'lane-task/details-company-documents/',
  showSingleFile:'lane-task/delete-company-document/',
  underWrittingListing:'lane-task/documents/',
  statusChange:'lane-task/update-document-status',
  replace:'lane-task/upload-document',
  uploadSingle:'lane-task/upload-and-ocr-document',
  uploadSingleNonOcr: 'lane-task/upload-document',
  uploadDiscountRateProof: 'documents/upload-document',
  getRoiUploadedFile:'documents/get-document',
  downloadRoiDoc:'documents/download-pdf',
  boardList: 'board',
  searchLane: 'lane/search',
  fetchLaneWithAccess: 'lane/board',
  laneTask: 'lane-task/search',
  createLanetask: 'lane-task',
  removeAssignee: 'lane-task/remove-assignee',
  laneTaskStats: 'lane-task/stats',
  removeWatchers: 'lane-task/remove-watchers',
  assignLaneTask: 'lane-task/assign-lanetask-to-other-lane',
  laneMovementValidate: 'lane-task/validate-lanetask',
  addAssigneToLaneTask: 'lane-task/add-assignee',
  addWatchers: 'lane-task/add-watcher',
  getTags: 'tag/get-boardtags',
  removeTags: 'lane-task/remove-tag',
  laneTaskDocumentsUpload: 'documents/upload-attachment',
  downloadZipTaskDocuments: 'documents/download-zip',
  downloadSingleDocument: 'documents/download-attachment',
  downloadAllFiles:'lane-task/download-uploaded-files/',
  deleteTaskDocuments: 'documents',
  uploadDocumentSearch: 'documents/search',
  laneConfig: 'lane-config',
  getUserManagement: 'users/v2/by-authority',
  getRmUserList: 'lead-tracker/get-rms-list',
  getFunnelData: 'funnelDashboard/get-data',
  saveAuthority: 'authority',
  laneTaskHistory: 'lane-task/lanetaskHistory',
  addComment: 'comment',
  commentTaskSearch: 'comment/search',
  configDocumentUpload: 'documents/upload-attachment',
  getConfigCountry: 'country/states',
  getCountryList: "get-countries",
  readBuyerDataFile: 'documents/view-data',
  termSheetGenerate: 'generate-pdf',
  generateTSFromBussiness: "api/util/html2pdf",
  termSheetSave: 'save-termsheet',
  rpaSheetGenerate: 'generate-rpa-pdf',
  updateLoginUserDetails: 'lane-task/update-login-user-details',
  rpaSheetGenerateAuto: 'rpa/upload-manual-rpa-document',
  rpaFiles: 'rpa/search-manual-rpa',
  rpaManualDownload: 'documents/download-attachment',
  rpaDelete: 'rpa/delete-rpa-doc',
  downloadRpaSheet: 'download-rpa-pdf',
  getRPAData: 'rpa',
  getTermSheetData: 'termsheet',
  // updateBuyerData:'lane-task/update-buyer-data',
  buyerData: 'buyer-data',
  uploadMultipleLead: 'api/upload/panjivaFileUpload',
  deleteTermsheet: 'termsheet/document',
  contactDetails: 'lane-task/contact-details',
  deleteContactDetails: 'lane-task/contact-details/delete',
  // addBuyerDataRow: 'operations/create-buyer-data',
  // deleteBuyerData: 'lane-task/delete-buyer-data',
  validateTermsheet: 'validate-termsheet',
  uploadBuyerDocs: 'buyer-data/upload-document',
  deleteBuyerData: 'buyer-data/delete-document',
  AcceptPreapreTermsheet: 'termsheet/accept',
  loadTermsheet: 'termsheet/get-term-sheet',
  termsheetStatus: 'termsheet/check-acceptance-status',
  updateBuyerDataConfig: 'board/update-index',
  laneAccessType: 'authority/board',
  getAccessByBoardId: 'board/boards',
  supplierBulkTermsheet: 'bulk-lead/upload-supplier-document',
  buyerBulkTermsheet: 'bulk-lead/upload-buyer-document',
  supplierTermsheetData: 'bulk-lead',
  downloadBulkLead: 'bulk-lead/download',
  downloadZipBulkLead: 'bulk-lead/download-zip',
  leadOwner: 'lane-task/change-lead-owner',
  saveInsurance: 'lane-task/save-other-insurer',
  getOtherInsurance: "lane-task/get-other-insurer",
  getPreCount: "lead/count",
  updateLeads: "lead/update-lead",
  getLeadsList: "lead-tracker/get-leads",
  searchLeads: "lead-tracker/search",
  creditLeadTracker: "lead-tracker/credit-tracker-search",
  addPreQualWatchers: "lead/add-watcher",
  changePreAssignee: "lead/change-assignee",
  getDocList: "documents/fetch-document",
  uploadPreDoc: "documents/upload-lead-attachment",
  downloadPreDOc: "documents/download-attachment",
  getPreComment: "lead/comment",
  addPreQualComment: "lead/comment/create",
  updatePreQualComment: "lead/comment/update",
  deletePreQualComment: "lead/comment/delete",
  removePreWatchers: "lead/remove-watchers",
  getRecords: "lead/fetch-records",
  getLeadSearch: "search/autosuggest/preslaes-leads",
  getWATemplate: "file-entry/fetch-template",
  updateLead: "file-entry/update-template",
  markContactPrimary: "lead/contact",
  buyerList: "search/autosuggest/preslaes-leads",
  sendWhatsApp: "lead/send",
  deleteBuyer: "lead/buyer/delete",
  requestPanjivaDetails: "lane-task/panjiva/request-details",
  fetchPanjivaDetails: "lane-task/panjiva/fetch-details",
  searchPanjivaDetails: "lane-task/panjiva/search-details",
  deleteContact: "lead/contact/delete",
  markTemplatePrimary: "file-entry/template-default",
  deleteTemplate: "file-entry/template",
  getPreHistory: "lead/history",
  downloadLeadSummary: "lead/download-lead-summary",
  saveAdditional: "lead-tracker/update-tracker-lead",
  deleteInsurance: "lane-task/delete-insurer-byId",
  changeMilestone: "lead-tracker/update-current-milestone",
  approvedBuyerList: 'termsheet/eximbuyerlist',
  deleteAppBuyer: 'termsheet/document',
  createApprovedBuyer: 'termsheet/create-eximbuyer',
  updateApprovedBuyer: 'termsheet/update-eximbuyer',
  uploadBuyerFile: 'buyer-data/upload-exim-buyer',
  downloadAppBuyers: 'buyer-data/download-exim-buyer',
  downloadApprovedBuyerTemplate: 'buyer-data/dummy-download-exim-buyer',
  getLOSComment: 'lead-tracker/get-comments',
  losTrackerComment: 'lead-tracker/add-comment',
  getMileStones: "lead-tracker/get-all-milestone",
  buyerTemplate : "excel-file",
  saveCreditTracker: "lead-tracker/add-creditor",
  panjivaBuyerList : "termsheet/panjiva/eximbuyerlist",
  defaultSetting:"score-card/defaults",
  scoreCount: "score-card/data",
  setSupplierData : "score-card/save-supplier-score-details",
  saveBuyerScore : "score-card/save-buyer-score-details",
  salesEditDisable : "score-card/editDisable",

  //MSAF
  loanInvoice: "entities/loan-invoices/search",
  invoiceFinanceDetails: "loan-invoice/invoice-finance-details",
  loanRequest: "loan-request/request",
  multiLoanRequest: "loan-request/request/multi",
  generate_drawDown_pdf: "loan-invoice/generate-drawdown-pdf",
  invoiceMultiFinanceDetails: "loan-invoice/invoice-finance-details/multi",
  lenderUTRMapMSAF: 'loan-payment/mark-paid/upload/v2',
  markDisbursedMulti: "loan-payment/mark-disbursed/multi",
  markDisbursed: "loan-payment/mark-disbursed",
  loanInvoiceUpload: "loan-invoice/upload/v2",
  getLenderDetails: "buyer-lender-config/get-buyer-config",
  updateLenderDetails: "buyer-lender-config/update",
  getLenderAccount: "get-lender-accounts",
  uploadLenderConfig: "buyer-lender-config/upload",
  loanPaymentMarkPaid: "loan-payment/mark-paid/upload/v2",
  getBasicDetails: "accounts/details",
  markDisbursedUpload: "loan-payment/mark-disbursed/upload/v2",
  getAccountSummary: "loan-request/get-account-statement",
  buyerUpload: "buyer/upload/v2",
  getBuyerGlobalSearchData: 'entities/buyer/search',
  autoCompleteVedantaSearchResult: "entities/autosuggest/search/buyers",

  //SID buyer/suppllier URLs
  buyerSearch: 'entities/accounts/buyers',
  supplierSearch: 'entities/accounts/suppliers',
  getCompanyInfo: 'company-info/info',
  createBuyer: 'accounts/sid/buyer',
  createSupplier: 'accounts/sid/supplier',
  uploadBulkSupplier: 'borrower/upload/V2',
  uploadBulkBuyer: 'buyer/upload/v2',
  sbMappingGetBuyers: 'sb-mapping/get-buyers',
  mappingSupplierBuyer: 'sb-mapping',
  getSupplierLenders: 'sl-mapping/get-mapping-by-supplier',
  getHeadersWithRecord: 'get-headers-with-records',
  getLenderListing: 'get-lender-accounts',
  slMapping: 'sl-mapping',
  sblMapping: 'sbl-mapping',
  mappingSupplierLenderBuyer: 'sbl-mapping/get-supplier-mapping',
  sbMapGetSupplier: 'sb-mapping/get-suppliers/v2',
  uploadInvoiceDocs: 'documents/upload-invoice',
  uploadBuyerApproveDocs: 'documents/upload-bulk-invoice?dataType=BUYER_APPROVAL',
  createInvoice: 'sid-invoice/create',
  getInvoiceListing: 'sid-invoice',
  getInvoiceStats: 'accounts/sid/supplier-stats',
  generateDrawdown: 'sid-invoice/generate-drawdown-pdf',
  sendInvoiceApproval: 'sid-invoice/send-for-approval',
  createBulkInvoice: 'sid-invoice/create-invoice-bulk',
  buyerAutoSuggest: 'entities/autosuggest',
  getInvoiceDetails: 'sid-invoice',
  adminApproval: 'sid-invoice/admin-approval',
  buyerApproval: 'sid-invoice/buyer-approval',
  rejectInvoiceBySidUser: 'sid-invoice/reject',
  getInvoiceDocuments: 'documents/invoice-documents',
  deleteInvoiceDoc: 'documents',
  downloadInvoiceDoc: 'documents/download',
  getMappedBuyer: 'sbl-mapping/get-mapped-buyers',
  rejectInvoiceFromBuyer: 'sid-invoice/buyer-reject',
  sendBuyerApproval: 'sid-invoice/buyer-request',
  updateStatus: 'accounts/update-status',
  paymentsUpload: 'sid-invoice/payments/upload',
  sendBuyerApprovalEmail: 'sid-invoice/send-buyer-approval-email',
  bulkApprovalAdmin: 'sid-invoice/bulk-approval/admin',
  bulkApprovalBuyer: 'sid-invoice/bulk-approval/buyer',
  multiBuyerRequest: 'sid-invoice/buyer-request/multi',
  supplierReportDownload: 'entities/accounts/suppliers/export',
  buyerReportDownload: 'entities/accounts/buyers/export',
  getColorCode: 'accounts/update-custom-config',
  downloadInvoicesReport: 'entities/invoices/export',
  getBuyerEmail: 'accounts/details',
  getBuyerDocs: 'documents/invoice-documents-by-type',
  getBuyerBulkDocs: 'documents/invoice-documents-by-type/bulk',
  sidInvMarkDisbursed: 'sid-invoice/payments/mark-disbursed',
  sidInvMarkPaid: 'sid-invoice/payments/mark-paid',
  downloadReport: "sid-report/create",
  downloadMisReport: "sid-report/create-lms-report",
  deleteInvoices: "sid-invoice/delete-invoices",
  downloadDisbursementFile: "disbursement-report",
  getOverDue: "sid-invoice/supplier-overdue-invoices",
  downloadDomesticlosFile: "reports/generate-los-report",
  updateVan: "virtual-accounts/update-van",
  getLimitDetails: "entities/get-buyer-stats",
  getNotification: "sid-invoice/notifications",
  dismissNotification: "sid-invoice/notifications/dismiss/",
  getODInvoices: 'sid-invoice/adjust-overdue-invoices',
  calculateRepAmount: 'sid-invoice/payments/fetch-repayment-amount',
  sendOdAdjustment: 'transaction-files/create/transaction-entry-for-adjustment',
  getMarkDisbursedFile: 'mis-dashboard/uploadDisbursedSheet',
  makDisbursedTemplate: 'mis-dashboard/download/template',
  saveDirectorOfSupplier: 'accounts/sid/save-director',
  getDirectorsOfSupplier: 'accounts/sid/get-director-of-supplier',
  getEPTracker: 'early-payment/ep-request-invoice-track',
  getMSPReport: 'mis-dashboard/generate-report',
  getEPInvoicesList: 'early-payment/ep-request-invoice-list',
  markEPFinance: 'early-payment/mark-invoice-payment-approval',

  //cibil
  getLenders: 'cibil/get-lenders',
  generateCibilConsumerReport: 'cibil/generate-cibil-consumer-report',

  //tasva
  tasvaMap: 'invoice/mark-tasva-suppliers',
  tasvaLedger: 'invoice/invoice-booking-tasva',

  //OKR
  createOKR: 'okr/create-goal',
  getAllOKR: "okr/search",
  OkRGoals: 'okr/goals',
  updateOKRGoal: 'okr',
  deleteOKRMember: 'okr/delete-members',
  //Presales
  leadConfig: 'file-config',
  updateLeadConfig: 'file-config/update-indexes',
  uploadConfig: 'file-entry/upload',
  fileEntries: 'file-entry/search',
  leadStats: 'search/presales-leads-stats',
  getFileDetails: 'search/presales-leads',
  leadUpdate: 'lead/update',
  leadMove: 'lead/move-to-ready',
  editLeadColumn: 'file-entry/update-config',
  leadDataSearch: 'lead',
  markLeadDispositioned: 'lead/mark-disposition',
  LOSHistory: 'lead/history',
  cinAutoFill: 'lead/autofill-cin-details',
  ratingAutoFill: 'lead/credit-rating-single-lead',
  IECAutoFill: 'lead/iec-details',
  bulkCinAutoFill: 'lead/autofill-cin-bulk',
  bulkRatingFill: 'lead/autofill-credit-rating-bulk',
  CreditRatingList: 'search/get-unique-values',
  LOSMoveLead: 'lead/send-to-los',
  downloadLOSLead: 'lead/download',
  uploadMappingHeaders: 'get-headers-with-records',
  paidUpCapitals: 'lead/cin-master-details-single-lead',
  phoneIec: 'lead/iec-details-single-lead',
  downloadTemplate: 'file-entry/download-template',
  bulkLOSMoveLead: 'lead/send-to-los/bulk',
  getLeadBySearch: 'search/autosuggest/presales-leads-search',
  bulkAssignee: "lead/change-bulk-assignee",
  getCampaignList: 'search/presales-leads-campaign',
  getLeadSourceList: 'search/presales-leads-leadSource',
  templateDownload: 'download-template',


  // ------anchor-financing-------//
  fetchBusinessAccounts: "business/fetch",
  createAnchorBusinessAccount: "business/anchor",
  fetchSupplierBusinessAccount: "business/supplier",
  supplierLenderSave: "business/supplier-lender",
  downloadSupplierTemplate: "business/supplier/download-template",
  uploadSupplierFile: "business/supplier/upload",
  uploadInvoiceFile: "invoice/bulk/upload",
  fetchAnchors: "business/fetch-anchors",
  createSupplierInvoice: "invoice/create",
  fetchSupplierInvoices: "invoice/fetch",
  downloadInvoiceTemplate: "invoice/download-template",
  exportInvoiceTamplate: "invoice/export-report",
  updateInvoiceBulkStatus: "invoice/bulk/update-status",
  updateInvoiceStatus: "invoice/update-status",
  fetchInvoicesForStatusMove: "invoice/validate-for-status-move",
  fetchStatusValidBulk: "invoice/fetch/validInvoiceMove",
  releasePayment: "invoice-payment/release",
  fetchInvoicePayments: "invoice-payment/fetch",
  checkGstIn: "business/check-gstin",
  fetchInvoiceStats: "stats/invoice",
  fetchSupplierStats: "stats/supplier",
  fetchLimitStats: "stats/limit-disbursement",
  fetchDiscountingTrend: "stats/discounting-trend",
  updateSupplierLenderStatus: "business/supplier-lender/update-status",
  vendorInvoiceFile : "invoice/upload",
  vendorSupplierList: "business/waree/supplier",
  vendorSupplierData : "stats/supplier-data",
  sendEp : "invoice/move",
  getInvocieHistory : "invoice/history",
  exportInvoice : "stats/invoice-report",
  vendorSupplier: "business/supplier-list",
  invoiceDocDownload : 'document/invoice-doc',

  //gst automate Url
  getGstListByPan: 'api/gst/get-gsts-by-pan',
  getGstReport: "api/gst/get-consolidated-gst-report",
  gstDownloadFile: 'api/gst/download-file',
  getSingleGSTReport: 'api/gst/get-gst-report-by-gst',
  shareGSTLink: "lane-task/share-gst-automate-verification-link",
  getStatusOfConsolidateReport: 'api/gst/generate-consolidated-gst-report',

  //EP Bucketing
  epBucketUploadCsv: "domestic/upload_csv_files",
  //partener-Program
  getPartnersList: 'lead-partner/get-partners',
  getActionOnPartner: 'lead-partner/approve-reject-partner',
  //signout

  logOutUser : 'users/logout',

  //vendor finance
  fetchInvoiceStatsData: "stats/invoice-data",
  getEarlyPaymentTrendData: "stats/ep-trend",
  getOverdueData: 'stats/overdue',

  //child lead
  childLead: 'lane-task/create-child-laneTask',
  getChildLead:'lane-task/search-childLead'
}

<div class="p-3">
  <div class="d-flex justify-content-between align-items-center mb-20">
    <h2 mat-dialog-title class="mb-0"> Child Leads</h2>
    <button mat-dialog-close class="cancel"><i class="ri-close-line"></i></button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isFetching"></mat-progress-bar>
  <div class="w-100 data-class">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef>S.No. </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{i+1}}
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Lead Id </th>
        <td mat-cell *matCellDef="let element">
          <span class="lead-id" (click)="openChildJourney(element.id)">{{element.displayId}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Lead Name </th>
        <td mat-cell *matCellDef="let element">
          {{element.name}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index">
      </tr>
    </table>
  </div>
</div>
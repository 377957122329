export class GlobalFilterData {
    setBizTypeValue: any = '';
    customInvoiceDate: any = '';
    customDueDate: any = '';
    setInvoiceStatus: any = ''
    customeApproved: any = '';
    customAddedCredlix: any = '';
    customExpiringIn: any = '';
    customRequestedIn: any = '';
    customDisbursedIn: any = '';
    searchInvoiceID: any;
    searchSupplierID: any;
    searchBuyerID: any;
}
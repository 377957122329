<div class="autoHederSearch vendorSearchInner">
  <input placeholder="Search Invoice/Supplier" type="text" [(ngModel)]="searchText"
    (input)="onSearchChange(searchText)" />

  <span class="serchIcon">
    <i class="ri-search-line"></i>
  </span>
  <i class="ri-close-line iconClose" *ngIf="showCloseIcon" (click)="resetSearchText()"></i>
  <button class="filterMenu" mat-icon-button [matMenuTriggerFor]="invoiceMenu" (click)="$event.stopPropagation();"
  *ngIf="!authService.isSIDRoleType() && anchorType != 'VEDANTA'">
    <span class="material-icons">
      filter_list
    </span>
  </button>
  <mat-menu class="invoiceMenu" #invoiceMenu="matMenu">
    <div class="header">
      <div class="rht">
        <span class="material-icons">
          close
        </span>
      </div>
    </div>
    <div class="content">
      <div class="common" (click)="$event.stopPropagation(); false;" *ngIf="!authService.isAccordUser()">
        <span class="lbl">Biz Type:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefBiz class="global_select" (selectionChange)="onBizTypeChange($event.value)"
            [(ngModel)]="globalFilterData.setBizTypeValue">
            <mat-option *ngFor="let type of bizTypeFilter" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Invoice Date -->
      <div class="common" (click)="$event.stopPropagation(); false;">
        <span class="lbl">Invoice Date:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefInvoice (selectionChange)="onFilterChange($event.value, 'invoice')"
            [(ngModel)]="globalFilterData.customInvoiceDate">
            <mat-option *ngFor="let type of invoiceDateList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <form [formGroup]="invoiceDateForm">
        <div class="common datePickercontent" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.customInvoiceDate ==-1">
          <span>Between</span>
          <mat-form-field appearance="fill" class="dash-selct">
            <input matInput [matDatepicker]="invoiceStartPicker" placeholder="Select Start Date"
              (dateChange)="changeFromDatePicker($event, 'invoice')" formControlName="startInvoiceDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="invoiceStartPicker"></mat-datepicker-toggle>
            <mat-datepicker #invoiceStartPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" class="dash-selct">
            <input matInput [matDatepicker]="invoiceEndPicker" placeholder="Select End Date"
              [min]="invoiceDateForm.controls.startInvoiceDate.value"
              (dateChange)="changeEndDatePicker($event, 'invoice')" formControlName="endInvoiceDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="invoiceEndPicker"></mat-datepicker-toggle>
            <mat-datepicker #invoiceEndPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
     <!-- Invoice Date End -->

      <!-- Due Date filter -->
      <div class="common" (click)="$event.stopPropagation(); false;">
        <span class="lbl">Due Date:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefDue (selectionChange)="onFilterChange($event.value, 'due')" [(ngModel)]="globalFilterData.customDueDate">
            <mat-option value=''>Anytime</mat-option>
            <mat-option value='today' *ngIf="globalFilterData.setInvoiceStatus !='ELIGIBLE'">Today</mat-option>
            <mat-option *ngFor="let type of dueDateList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <form [formGroup]="dueDateForm">
      <div class="common datePickercontent" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.customDueDate ==-1">
        <span>Between</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <input matInput [matDatepicker]="dueStartPicker" placeholder="Select Start Date"
            (dateChange)="changeFromDatePicker($event, 'due')" formControlName="startDueDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="dueStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #dueStartPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" class="dash-selct">
          <input matInput [matDatepicker]="dueEndPicker" placeholder="Select End Date" [min]="dueDateForm.controls.startDueDate.value"
            (dateChange)="changeEndDatePicker($event, 'due')" formControlName="endDueDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="dueEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #dueEndPicker></mat-datepicker>
        </mat-form-field>
      </div>
      </form>
      <!-- Due Date End -->

      <div class="common" (click)="$event.stopPropagation(); false;">
        <span class="lbl">Status:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefStatus (selectionChange)="onInvoiceStatusChange($event.value)"
            [(ngModel)]="globalFilterData.setInvoiceStatus">
            <mat-option value=""> Select</mat-option>
            <mat-option *ngFor="let type of InvoiceFilterList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="glb_border"></div>

      <!-- added to credlix -->
      <div class="common" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.setInvoiceStatus !=''">
        <span class="lbl">Added to Credlix:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefAdded (selectionChange)="onFilterChange($event.value, 'added')"
            [(ngModel)]="globalFilterData.customAddedCredlix">
            <mat-option *ngFor="let type of addedCredlixList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <form [formGroup]="addedCredlixForm" *ngIf="globalFilterData.customAddedCredlix ==-1">
      <div class="common datePickercontent" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.customAddedCredlix ==-1">
        <span>Between</span>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customAddedCredlix ==-1">
          <input matInput [matDatepicker]="credlixStartPicker" placeholder="Select Start Date"
            (dateChange)="changeFromDatePicker($event, 'added')" formControlName="startAddedDate" [max]="tomorrow" readonly>
          <mat-datepicker-toggle matSuffix [for]="credlixStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #credlixStartPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customAddedCredlix ==-1">
          <input matInput [matDatepicker]="credlixEndPicker" placeholder="Select End Date"
            (dateChange)="changeEndDatePicker($event, 'added')" formControlName="endAddedDate"  
            [min]="addedCredlixForm.controls.startAddedDate.value"
             [max]="tomorrow" readonly>
          <mat-datepicker-toggle matSuffix [for]="credlixEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #credlixEndPicker></mat-datepicker>
        </mat-form-field>
      </div>
      </form>
      <!-- added to credlix End -->

      <!-- Approved -->
      <div class="common" (click)="$event.stopPropagation(); false;"
        *ngIf="globalFilterData.setInvoiceStatus !='' && globalFilterData.setInvoiceStatus !='APPROVAL_AWAITED' ">
        <span class="lbl">Approved:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefApproved (selectionChange)="onFilterChange($event.value, 'approved')"
            [(ngModel)]="globalFilterData.customeApproved">
            <mat-option value=''>Anytime</mat-option>
            <mat-option value="approved" *ngIf="globalFilterData.setInvoiceStatus =='EXPIRED'"> Not Approved</mat-option>
            <mat-option *ngFor="let type of approvedList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <form [formGroup]="approvedForm" *ngIf="globalFilterData.customeApproved ==-1">
      <div class="common datePickercontent" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.customeApproved ==-1" >
        <span>Between</span>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customeApproved ==-1">
          <input matInput [matDatepicker]="customStartPicker" placeholder="Select Start Date"
            (dateChange)="changeFromDatePicker($event, 'approved')" formControlName="startApprovedDate" [max]="tomorrow" readonly>
          <mat-datepicker-toggle matSuffix [for]="customStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #customStartPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customeApproved ==-1">
          <input matInput [matDatepicker]="customEndPicker" placeholder="Select End Date"
            (dateChange)="changeEndDatePicker($event, 'approved')" [max]="tomorrow" formControlName="endApprovedDate"
            [min]="approvedForm.controls.startApprovedDate.value" readonly>
          <mat-datepicker-toggle matSuffix [for]="customEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #customEndPicker></mat-datepicker>
        </mat-form-field>
      </div>
      </form>
      <!-- Approved End -->
      
      <!-- Expiring In -->
      <div class="common expiring" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.setInvoiceStatus =='ELIGIBLE'">
        <span class="lbl">Expiring in:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefExpiring (selectionChange)="onFilterChange($event.value, 'expiring')"
            [(ngModel)]="globalFilterData.customExpiringIn">
            <mat-option *ngFor="let type of expiringIn" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <form [formGroup]="expiringForm" *ngIf="globalFilterData.customExpiringIn ==-1">
      <div class="common datePickercontent" (click)="$event.stopPropagation(); false;"  *ngIf="globalFilterData.customExpiringIn ==-1">
        <span>Between</span>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customExpiringIn ==-1">
          <input matInput [matDatepicker]="expiringStartPicker" formControlName="startExpiringDate" placeholder="Select Start Date"
            (dateChange)="changeFromDatePicker($event, 'expiring')" [min]="minDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="expiringStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #expiringStartPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customExpiringIn ==-1">
          <input matInput [matDatepicker]="expiringEndPicker" placeholder="Select End Date"
            (dateChange)="changeEndDatePicker($event, 'expiring')" [min]="minDate" formControlName="endExpiringDate"
            [min]="expiringForm.controls.startExpiringDate.value" readonly>
          <mat-datepicker-toggle matSuffix [for]="expiringEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #expiringEndPicker></mat-datepicker>
        </mat-form-field>
      </div>
      </form>

      <!-- Expiring In End -->

      <!-- Requested In Start -->
      <div class="common expiring" (click)="$event.stopPropagation(); false;"
        *ngIf="globalFilterData.setInvoiceStatus =='PAYMENT_REQUESTED' || globalFilterData.setInvoiceStatus=='PAID' || globalFilterData.setInvoiceStatus=='PARTIALLY_PAID'">
        <span class="lbl">Requested in:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefRequested (selectionChange)="onFilterChange($event.value, 'request')"
            [(ngModel)]="globalFilterData.customRequestedIn">
            <mat-option *ngFor="let type of requestedList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <form [formGroup]="requestForm" *ngIf="globalFilterData.customRequestedIn ==-1">
      <div class="common datePickercontent" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.customRequestedIn ==-1" >
        <span>Between</span>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customRequestedIn ==-1">
          <input matInput [matDatepicker]="requestStartPicker" placeholder="Select Start Date"
            (dateChange)="changeFromDatePicker($event, 'request')" [max]="tomorrow" formControlName="startRequestDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="requestStartPicker"></mat-datepicker-toggle>
          <mat-datepicker #requestStartPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customRequestedIn ==-1">
          <input matInput [matDatepicker]="requestEndPicker" placeholder="Select End Date"
            (dateChange)="changeEndDatePicker($event, 'request')" [max]="tomorrow" formControlName="endRequestDate"
            [min]="requestForm.controls.startRequestDate.value" readonly>
          <mat-datepicker-toggle matSuffix [for]="requestEndPicker"></mat-datepicker-toggle>
          <mat-datepicker #requestEndPicker></mat-datepicker>
        </mat-form-field>
      </div>
      </form>

      <!-- Requested In End -->
       
      <!-- Disbursed In Start --> 
      <div class="common expiring" (click)="$event.stopPropagation(); false;"
      *ngIf="globalFilterData.setInvoiceStatus =='DISBURSED' ">
      <span class="lbl">Disbursed in:</span>
      <mat-form-field appearance="fill" class="dash-selct">
        <mat-select #matRefRequested (selectionChange)="onFilterChange($event.value, 'disbursed')"
          [(ngModel)]="globalFilterData.customDisbursedIn">
          <mat-option *ngFor="let type of requestedList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <form [formGroup]="disbursedForm" *ngIf="globalFilterData.customDisbursedIn ==-1">
    <div class="common datePickercontent" (click)="$event.stopPropagation(); false;" *ngIf="globalFilterData.customDisbursedIn==-1" >
      <span>Between</span>
      <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customDisbursedIn ==-1">
        <input matInput [matDatepicker]="disbursedStartPicker" placeholder="Select Start Date"
          (dateChange)="changeFromDatePicker($event, 'disbursed')" [max]="tomorrow" formControlName="startDisbursedDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="disbursedStartPicker"></mat-datepicker-toggle>
        <mat-datepicker #disbursedStartPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="dash-selct" *ngIf="globalFilterData.customDisbursedIn ==-1">
        <input matInput [matDatepicker]="disbursedEndPicker" placeholder="Select End Date"
          (dateChange)="changeEndDatePicker($event, 'disbursed')" [max]="tomorrow" formControlName="endDisbursedDate"
          [min]="disbursedForm.controls.startDisbursedDate.value" readonly>
        <mat-datepicker-toggle matSuffix [for]="disbursedEndPicker"></mat-datepicker-toggle>
        <mat-datepicker #disbursedEndPicker></mat-datepicker>
      </mat-form-field>
    </div>
    </form>

    <!-- Disbursed In End -->


      <!-- <div class="common" (click)="$event.stopPropagation(); false;" *ngIf="setInvoiceStatus =='EXPIRED'">
        <span class="lbl">Approve:</span>
        <mat-form-field appearance="fill" class="dash-selct">
          <mat-select #matRefBiz class="global_select" (selectionChange)="onApprovedChecked($event.value)"
            [(ngModel)]="setApprovedCheck">
            <mat-option *ngFor="let type of approvedCheckList" [value]="type.roleStatus">{{type.roleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="common invcid" (click)="$event.stopPropagation(); false;">
        <span class="lbl">Invoice Id:</span>
        <mat-form-field class="inputBox" floatLabel="never">
          <input matInput [(ngModel)]="globalFilterData.searchInvoiceID" placeholder="Enter Invoice ID">
        </mat-form-field>
      </div>
      <div class="common invcid" (click)="$event.stopPropagation(); false;" *ngIf="!isChannelFinancing">
        <span class="lbl">Supplier Id:</span>
        <mat-form-field class="inputBox" floatLabel="never">
          <input matInput [(ngModel)]="globalFilterData.searchSupplierID" placeholder="Enter Supplier ID">
        </mat-form-field>
      </div>

      <div class="common invcid" (click)="$event.stopPropagation(); false;"
      *ngIf="isChannelFinancing">
        <span class="lbl">Buyer Id:</span>
        <mat-form-field class="inputBox" floatLabel="never">
          <input matInput [(ngModel)]="globalFilterData.searchBuyerID" placeholder="Enter Buyer ID">
        </mat-form-field>
      </div>

      <div class="cta">
        <button type="button" (click)="applyGlobalSearchFilter(true)">Search</button>
      </div>
    </div>
  </mat-menu>
</div>
<div class="searchedListing" *ngIf="showListing && searchText.length>1">
  <div class="sk-fading-circle" *ngIf="autoSuggestloading">
    <div class="sk-circle1 sk-circle"></div>
    <div class="sk-circle2 sk-circle"></div>
    <div class="sk-circle3 sk-circle"></div>
    <div class="sk-circle4 sk-circle"></div>
    <div class="sk-circle5 sk-circle"></div>
    <div class="sk-circle6 sk-circle"></div>
    <div class="sk-circle7 sk-circle"></div>
    <div class="sk-circle8 sk-circle"></div>
    <div class="sk-circle9 sk-circle"></div>
    <div class="sk-circle10 sk-circle"></div>
    <div class="sk-circle11 sk-circle"></div>
    <div class="sk-circle12 sk-circle"></div>
  </div>
  <div *ngIf="suggestedInvoices.length>0 && autoSuggestloading == false">
    <span>Invoices</span>
    <ul *ngFor="let list of suggestedInvoices; let i = index">
      <li (click)="applySearch(list)">
        {{list.searchkey}}<span>{{list.supplierName}}</span></li>
    </ul>
  </div>
  <div class="allResults" *ngIf="searchResultListing.length>=0 && anchorType != 'VEDANTA'" (click)="applySearch(searchText)">
    View all results for Invoice "{{this.searchText}}"
  </div>

  <div *ngIf="suggestedSuppliers.length>0 && autoSuggestloading == false">
    <span>Suppliers</span>
    <ul *ngFor="let list of suggestedSuppliers;let i = index">
      <li (click)=" applySupplierSearch(list)">{{list.searchkey | titlecase}} {{list.businessName | titlecase}}
      </li>
    </ul>
  </div>
  <div class="allResults" *ngIf="searchResultListing.length>=0 && !isChannelFinancing" (click)="applySupplierSearch(searchText)">
    View all results for Suppliers "{{this.searchText}}"
  </div>

  <div *ngIf="suggestedBuyer.length> 0 && (autoSuggestloading == false && 
    (authService.isSIDRoleType() || isChannelFinancing))">
    <span>Buyers</span>
    <ul *ngFor="let list of suggestedBuyer;let i = index">
      <li (click)=" applySupplierSearch(list, 'buyer')">{{list.searchkey | titlecase}} {{list.businessName | titlecase}}
      </li>
    </ul>
  </div>
  <div class="allResults" *ngIf="searchResultListing.length>=0" (click)="applySupplierSearch(searchText, 'buyer')">
    View all results for Buyers "{{this.searchText}}"
  </div>
</div>
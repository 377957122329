import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { KanbanService } from '../../kanban.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-child-lead-popup',
  templateUrl: './view-child-lead-popup.component.html',
  styleUrls: ['./view-child-lead-popup.component.scss']
})
export class ViewChildLeadPopupComponent implements OnInit {
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: any[] = ['sno','id', 'name'];
  isFetching: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public _kService: KanbanService,
    public toasterService: ToastrService,
    private router: Router) { }

  ngOnInit() {
    this.fetchChildLeads();
  }

  fetchChildLeads() {
    this.isFetching = true;
    this._kService.getChildLeads(this.data).subscribe({
      next: (resp: any) => {
        this.dataSource.data = resp.result;
      },
      error: () => this.isFetching = false,
      complete: () => this.isFetching = false
    })
  }
  openChildJourney(childLaneTaskId) {
    window.location.href = '/#/cardDetails/' + childLaneTaskId;
  }
}
